import { http, authHeader } from '@/http-commons';
class TipoSalidaDataService{

    create( tipoSalida ){
        return http.post('api/v1/catalogo/tipoSalida/store', tipoSalida, { headers : authHeader() });
    }

    show( id ){
        return http.get(`api/v1/catalogo/tipoSalida/show/${id}`, { headers : authHeader() });
    }

    update( id, tipoSalida ){
        return http.put(`api/v1/catalogo/tipoSalida/update/${id}`, tipoSalida, { headers : authHeader() });
    }

    delete( id ){
        return http.delete(`api/v1/catalogo/tipoSalida/delete/${id}`, { headers : authHeader() });
    }

    getAllTipoSalida(){
        return http.get('api/v1/catalogo/tipoSalida/all', { headers : authHeader() });
    }

    getTipoSalidaTable( params ){
        return http.get('api/v1/catalogo/tipoSalida/listaTipoSalida', { params, headers : authHeader() });
    }
}
export default new TipoSalidaDataService();