<template>
    <v-container>
        <v-row dense>
            <v-col v-for="( item, i) in reportes" :key="i" cols="12" md="6" sm="6">
                <v-card :color="item.color" dark>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="headline" v-text="item.titulo">
                            </v-card-title>
                            <v-card-subtitle v-text="item.subtitulo">
                            </v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-3" fab icon height="40px"  right width="40px"
                                @click="item.onClick">
                                    <v-icon class="mr-2" large>{{item.report}}</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </div>
                        <v-avatar class="ma-3" size="125" tile>
                            <v-icon x-large>{{item.icon}}</v-icon>
                        </v-avatar>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <ExcelExportVenta :dialog.sync="dialogExcelExport" :handleModal="handleExcelExport" 
        :tipo-salida.sync="tipoSalida" :sucursales.sync="sucursales" :is-admin="isAdmin"
        v-if="dialogExcelExport"/>
        <ExcelExportSellDebts :dialog.sync="dialogExcelSellDebtExport" :handleModal="handleExcelSellDebtExport"
        :sucursales.sync="sucursales" :is-admin="isAdmin" v-if="dialogExcelSellDebtExport"/>
    </v-container>

</template>
<script>
import TipoSalidaDataService from '@/service/venta/TipoSalidaDataService';
import ExcelExportVenta from '../venta/ExcelExportVenta.vue';
import ExcelExportSellDebts from '../venta/ExcelExportSellDebts.vue';
import tipoPagoDataService from '@/service/catalogo/tipoPago/tipoPagoDataService';
import sucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import usuarioDataService from '@/service/usuario/usuarioDataService';

export default {
    name: 'Reportes',
    components: {
        ExcelExportVenta, ExcelExportSellDebts
    },
    created() {
        this.getAllTipoSalida();
        this.getAllTipoPago();
        this.getAllSucursal();
    },
    data() {
        return {
            tipoSalida: [],
            tipoPagos: [],
            sucursales: [],
            dialogExcelExport: false,
            dialogExcelSellDebtExport: false,
            reportes: [
                {   color: 'indigo darken-1', titulo: 'Reporte de entradas',
                    subtitulo: 'Ingresos granja san Fernando',
                    report: 'fas fa-file-excel',
                    icon: 'fas fa-exchange-alt',
                    onClick: () => this.handleExcelExport()
                },
                {
                    color: 'green darken-3', titulo: 'Reporte de ventas',
                    subtitulo: 'Ventas san Fernando',
                    report: 'fas fa-file-excel',
                    icon: 'fas fa-file-invoice-dollar',
                    onClick: () => this.handleExcelExport()
                },
                {
                    color: 'deep-purple lighten-1', titulo : 'Reporte de productividad',
                    subtitulo: 'Productividad san Fernando',
                    report: 'fas fa-file-excel',
                    icon: 'fas fa-chart-line',
                    onClick: () => this.handleExcelExport()
                },
                {
                    color: 'red darken-1', titulo: 'Reporte de cobranza',
                    subtitulo: 'Cobranza san Fernando',
                    report: 'fas fa-file-excel',
                    icon: 'fas fa-money-check-alt',
                    onClick: () => this.handleExcelSellDebtExport()
                }
            ],
        }
    },
    methods: {
        handleExcelExport() { this.dialogExcelExport = !this.dialogExcelExport },
        handleExcelSellDebtExport() { this.dialogExcelSellDebtExport = !this.dialogExcelSellDebtExport },
        async getAllTipoSalida(){ this.tipoSalida = await TipoSalidaDataService.getAllTipoSalida().then( resp => resp.data ) || [] },
        async getAllTipoPago(){ this.tipoPagos = await tipoPagoDataService.allTipoPago().then( rsp => rsp.data ) || []},
        async getAllSucursal(){ this.sucursales = await sucursalDataService.lstSucursal().then( resp => resp.data ) || [] }
    },
    computed: {
        isAdmin(){ return usuarioDataService.getUserRoles().includes('ROLE_ADMIN'); }
    }
}
</script>